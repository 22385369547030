@font-face {
  font-family: 'Comic Neue Angular';
  src: url('../fonts/ComicNeue-Angular-Regular.eot');
  src: url('../fonts/ComicNeue-Angular-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ComicNeue-Angular-Regular.woff2') format('woff2'),
  url('../fonts/ComicNeue-Angular-Regular.woff') format('woff'),
  url('../fonts/ComicNeue-Angular-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Comic Neue Angular';
  src: url('../fonts/ComicNeue-Angular-Regular-Oblique.eot');
  src: url('../fonts/ComicNeue-Angular-Regular-Oblique.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ComicNeue-Angular-Regular-Oblique.woff2') format('woff2'),
  url('../fonts/ComicNeue-Angular-Regular-Oblique.woff') format('woff'),
  url('../fonts/ComicNeue-Angular-Regular-Oblique.ttf') format('truetype');
  font-weight: 400;
  font-style: oblique;
}

@font-face {
  font-family: 'Comic Neue Angular';
  src: url('../fonts/ComicNeue-Angular-Bold.eot');
  src: url('../fonts/ComicNeue-Angular-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ComicNeue-Angular-Bold.woff2') format('woff2'),
  url('../fonts/ComicNeue-Angular-Bold.woff') format('woff'),
  url('../fonts/ComicNeue-Angular-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Comic Neue Angular';
  src: url('../fonts/ComicNeue-Angular-Bold-Oblique.eot');
  src: url('../fonts/ComicNeue-Angular-Bold-Oblique.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ComicNeue-Angular-Bold-Oblique.woff2') format('woff2'),
  url('../fonts/ComicNeue-Angular-Bold-Oblique.woff') format('woff'),
  url('../fonts/ComicNeue-Angular-Bold-Oblique.ttf') format('truetype');
  font-weight: 700;
  font-style: oblique;
}

//h1, h2, h3, h4, h5, h6 {
//  font-family: 'Comic Neue Angular', $font-family-sans-serif;
//  font-weight: 700;
//  font-feature-settings: "kern";
//  -moz-font-feature-settings: "kern";
//  -webkit-font-feature-settings: "kern";
//}
//
//nav {
//  font-family: 'Comic Neue Angular', $font-family-sans-serif;
//}

.theme-font {
  font-family: 'Comic Neue Angular', sans-serif !important;
  //font-weight: 400;
  //font-size: 1em;
  //line-height: 1.5;
}

