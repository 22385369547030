/*
 * Base structure
 */

/* Enforce the visible scroll-bar so we don't get popping from dynamic properties */
html {
  overflow-y: scroll;
}

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  //padding-top: 50px;
}

/*
 * Typography
 */

h1 {
  margin-bottom: 20px;
  padding-bottom: 9px;
  border-bottom: 1px solid #eee;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 51px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  border-right: 1px solid #eee;
}

/* Sidebar navigation */
.sidebar {
  padding-left: 0;
  padding-right: 0;
}

.sidebar .nav {
  margin-bottom: 20px;
}

.sidebar .nav-item {
  width: 100%;
}

.sidebar .nav-item + .nav-item {
  margin-left: 0;
}

.sidebar .nav-link {
  border-radius: 0;
}

/*
 * Dashboard
 */

/* Placeholders */
.placeholders {
  padding-bottom: 3rem;
}

.placeholder img {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* Vue Transition Styles */
.expand-enter-active, .expand-leave-active, .expand-transition {
  transition: all 0.5s ease;
}

/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter, .expand-leave-to {
  height: 0;
  opacity: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 33%;
  margin: 0 auto;
  transition: all 0.1s ease;
}

.modal-wide .modal-container {
  width: 80%;
}

.modal-container .card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal-leave-active .modal-container {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.occurrenceRow {
  background-color: #eee;
  &:hover {
    background-color: rgba(0, 0, 0, .075);
  }
}

.ticketRow {
  background-color: #eee;
  &:hover {
    background-color: rgba(0, 0, 0, .075);
  }
}

//[v-cloak] {
//  display: none;
//}
[v-cloak] > * {
  display: none
}

[v-cloak]::before {
  content: "Loading ...";
  font-size: 2rem;
  position: absolute;
  color: #777777;
  left: 40%;
  //margin-left: -100px;
}

[v-cloak] .v-cloak--block {
  display: block;
}

[v-cloak] .v-cloak--inline {
  display: inline;
}

[v-cloak] .v-cloak--inlineBlock {
  display: inline-block;
}

.v-cloak--block,
.v-cloak--inline,
.v-cloak--inlineBlock {
  display: none;
}

.monospace {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
}

@for $i from 1 through 8 {
  $j: $i + 1;
  .border-#{$i} {
    outline: #{$i}px solid $brand-primary;
    outline-offset: -#{$j}px;
  }
}
